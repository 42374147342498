<template>
  <v-card flat class="ml-8 mr-8">
    <v-card-title class="mt-6">
      <menu-title title="Marketing Resources"></menu-title>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="6" align="left">
          <v-text-field
            outlined
            hide-details
            v-model="search"
            label="Search..."
            prepend-icon="mdi-file-search-outline"
            class="pl-2"
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-select
            outlined
            hide-details
            v-model="applicationFilter"
            label="Solution"
            :items="applicationFilterOptions"
            :item-text="(item) => item.name"
            :item-value="(item) => item.value"
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            outlined
            hide-details
            v-model="languageFilter"
            label="Language"
            :items="languageFilterOptions"
            :item-text="(item) => item.name"
            :item-value="(item) => item.value"
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            outlined
            hide-details
            v-model="typeFilter"
            label="Resource Type"
            :items="typeFilterOptions"
            :item-text="(item) => item.name"
            :item-value="(item) => item.value"
          ></v-select>
        </v-col>
      </v-row>
      <v-divider class="my-6"></v-divider>
      <v-row>
        <v-col
          cols="12"
          class="mt-6"
          align="center"
          v-if="filteredFiles.length == 0"
        >
          <span style="font-size: 1.5rem; color: #b31616"
            >Oops, looks like nothing was found<br
          /></span>
          <span style="font-size: 1.25rem; color: #b31616"
            >You might want to search and filter for something else</span
          >
        </v-col>
        <v-col
          cols="4"
          v-for="(resource, index) in filteredFiles"
          :key="index"
        >
          <resource-documentation-card
            :name="resource.displayName"
            :language="resource.language"
            :link="resource.link"
          >
          </resource-documentation-card>
          <!-- <resource-card
            :file="resource.fileName"
            :name="resource.displayName"
            :language="resource.language"
          ></resource-card> -->
        </v-col>
      </v-row>
    </v-card-text>
    <resource-dialog
      v-model="previewDialog"
      v-if="previewDialog"
      :resource="selectedResource"
    ></resource-dialog>
  </v-card>
</template>

<script>
import menuTitle from "../../components/helpers/menu_title.vue";
import { saveAs } from "file-saver";
import resourceDialog from "../../components/resource_dialog.vue";
import axios from "axios";
import CountryFlag from "vue-country-flag";
import ResourceCard from "../../components/resource_card.vue";
import resourceDocumentationCard from "../../components/resource_documentation_card.vue";

export default {
  name: "Resources",
  components: {
    menuTitle,
    resourceDialog,
    CountryFlag,
    ResourceCard,
    resourceDocumentationCard,
  },
  computed: {
    filteredFiles: function () {
      var res = this.files;
      if (this.search) {
        res = res.filter((x) =>
          x.displayName.toLowerCase().includes(this.search.toLowerCase())
        );
      }
      if (this.applicationFilter) {
        res = res.filter((x) => x.application == this.applicationFilter);
      }
      if (this.languageFilter) {
        res = res.filter((x) => x.language == this.languageFilter);
      }
      if (this.typeFilter) {
        res = res.filter((x) => x.type == this.typeFilter);
      }
      return res;
    },
  },
  methods: {
    debug: function (fileName) {
      console.log("debug...");
      console.log(fileName);
      const pdfFile = require(`../../assets/marketing_material/${fileName}.pdf`);
      console.log(pdfFile);
      axios
        .get("http://localhost:8081" + pdfFile)
        .then((response) => {
          //console.log("Response:");
          //console.log(response);
          var length = Number(response.headers["content-length"]);
          console.log(length);
          console.log(length / 1000 / 1000);
        })
        .catch((error) => {
          console.log(`Error: ${error}`);
        });
      console.log("----------");
    },
    getImage: function (fileName) {
      return require(`../../assets/marketing_material/${fileName}.png`);
    },
    getPdf: function (fileName) {
      return require(`../../assets/marketing_material/${fileName}.pdf`);
    },
    downloadFile: function (file) {
      const pdf = this.getPdf(file.fileName);
      saveAs(pdf, file.displayName + ".pdf");
    },
  },
  data: () => ({
    fabs: {},
    selectedResource: {},
    previewDialog: false,
    search: "",

    applicationFilter: "",
    applicationFilterOptions: [
      { name: "All Solutions", value: "" },
      { name: "COBI.edi", value: "edi" },
      { name: "COBI.msv", value: "msv" },
      { name: "COBI.ppc", value: "ppc" },
      { name: "COBI.time", value: "time" },
      { name: "COBI.wms", value: "wms" },
    ],

    languageFilter: "",
    languageFilterOptions: [
      { name: "All Languages", value: "" },
      { name: "English", value: "gb" },
      { name: "German", value: "de" },
    ],

    typeFilter: "",
    typeFilterOptions: [
      { name: "All types", value: "" },
      { name: "Factsheets", value: "factsheet" },
      { name: "Information", value: "information" },
      { name: "Solution Overview", value: "solution_overview" },
      { name: "Manuals", value: "manual" },
      { name: "Roadmap", value: "roadmap" },
    ],
    files: [
      {
        application: "time",
        displayName: "COBI.time Lösungsüberblick",
        language: "de",
        type: "solution_overview",
        link: "https://cobisoft.de/wp-content/uploads/2022/06/DE-COBItime-Loesungsueberblick.pdf",
      },
      {
        application: "wms",
        displayName: "COBI.wms Lösungsüberblick",
        language: "de",
        type: "solution_overview",
        link: "https://cobisoft.de/wp-content/uploads/2022/06/DE-COBIwms-Loesungsueberblick.pdf",
      },
      {
        application: "pcc",
        displayName: "COBI.ppc Lösungsüberblick",
        language: "de",
        type: "solution_overview",
        link: "https://cobisoft.de/wp-content/uploads/2022/10/DE-Factsheet-COBI.ppc_.pdf",
      },
      {
        application: "edi",
        displayName: "COBI.edi Lösungsüberblick",
        language: "de",
        type: "solution_overview",
        link: "https://cobisoft.de/wp-content/uploads/2022/07/DE-COBI.edi-Factsheet.pdf",
      },
      {
        application: "msv",
        displayName: "COBI.msv3 Lösungsüberblick",
        language: "de",
        type: "solution_overview",
        link: "https://cobisoft.de/wp-content/uploads/2021/04/DE-COBI.msv-Factsheet.pdf",
      },

      {
        application: "time",
        displayName: "COBI.time Solution Overview english",
        language: "gb",
        type: "solution_overview",
        link: "https://cobisoft.de/wp-content/uploads/2022/06/ENG-COBItime-Solution-Overview.pdf",
      },
      {
        application: "wms",
        displayName: "COBI.wms Solution Overview",
        language: "gb",
        type: "solution_overview",
        link: "https://cobisoft.de/wp-content/uploads/2022/06/ENG-COBIwms-Solution-Overview.pdf",
      },
      {
        application: "pcc",
        displayName: "COBI.ppc Solution Overview",
        language: "gb",
        type: "solution_overview",
        link: "https://cobisoft.de/wp-content/uploads/2022/10/ENG-Factsheet-COBIppc.pdf",
      },
      {
        application: "edi",
        displayName: "COBI.edi Solution Overview",
        language: "gb",
        type: "solution_overview",
        link: "https://cobisoft.de/wp-content/uploads/2022/07/ENG-COBI.edi-Factsheet.pdf",
      },
      {
        application: "msv",
        displayName: "COBI.msv3 Solution Overview",
        language: "gb",
        type: "solution_overview",
        link: "https://cobisoft.de/en/cobimsv/",
      },
    ],
  }),
};
</script>
<style scoped>
.show_outline {
  outline: black;
}
.no_results_span_1 {
  font-size: 1.5rem !important;
}
.no_results_span_2 {
  font-size: 1.2rem !important;
}
</style>